import React from 'react';
import '../App.css';
import Nav from '../components/navBar'
import projectCard from '../components/projectCard'
import {projects} from '../module/projects'

export default () => 
  <>
    <header>
      <Nav />
    </header>
    <div className="projects">
      <h2>Projects</h2>
      <div className="container-flex-card">  
        {projects.map(projectCard)}
      </div>
    </div>
    <footer>
    </footer>
  </>