import React from 'react';
import '../App.css';
import Nav from '../components/navBar'
import {
  Card, CardImg, CardText, CardBody,
  CardSubtitle} from 'reactstrap';
import SocialMedia from '../components/socialMedia'

export default () => 
<>
  <header>
    <Nav />
  </header>
  <div className="experience">
    <div className="container-flex-card" >
    <div style={{marginTop : '30px', maxWidth : "350px", width : '100%'}}>
      <Card>
        <CardImg top width="100%" height="auto" alt="Card image cap" src={require('../images/me.jfif')} />
        <div className="container-flex" style={{padding : '20px 10px 0px 10px'}}>
          <SocialMedia />
        </div>
        <CardBody>
          <CardText>
              <h3>Rashad Barnett</h3>
              <CardSubtitle>
                <h5>Software Engineer</h5>
                <h5>Rashad@RashadBarnett.com</h5>
              </CardSubtitle>
          </CardText>  
        </CardBody>
      </Card>
    </div>
    </div>
  </div>
  {/* <div className="projects">
    <h2>Projects</h2>
    <div className="container-flex-card">  
      {projects.map(projectCard)}
    </div>
  </div> */}
  <footer>
  </footer>
</>