import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import {Link as RRNavLink } from "react-router-dom";

const links = [
  { href: '/', text: 'Home', img : '' },
  { href: '/portfolio', text: 'Portfolio', img : '' },
  { href: '/contact', text: 'Contact', img : '' },
];

const CreateNavItem = ({ href, text, className='', site } : {href : string, text: string, className?: string, site?: string }) => (
  <NavItem key={text}>
    <NavLink 
      tag={RRNavLink}
      to={href} 
      onClick={() => site && window.open(site)} 
      className={className}>{text}</NavLink>
  </NavItem>
);

export default () => {
  const [isOpen, setIsOpen] = useState(false);  
    return (
        <Navbar fixed="top" color="light" light expand="md">
          <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
              {links.map(({href, text }) => <CreateNavItem href={href} text={text} />)}
            </Nav>
          </Collapse>
        </Navbar>
    );
  }
