import {render} from 'react-dom';
import { 
    Route,
    Switch,
    HashRouter 
} from 'react-router-dom'
import App from './App';
import portfolio from './pages/Portfolio';
import contact from './pages/contact';

render ((
    <HashRouter basename="/">
        <Switch>
            <Route exact path="/" component={App} />
            <Route path="/portfolio" component={portfolio} />
            <Route path="/contact" component={contact} />
        </Switch>
    </HashRouter>
),document.getElementById("root"));