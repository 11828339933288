export const projects = [
    {
        img : require('../images/projects/cryptoplex-logo.jpg'),
        projectTitle : "Cryptoplex Mangement",
        subTitle : "Ethereum Wallet API",
        position : "Backend Developer",
        description : "Used various technologies to build a backend that automates financial tracking of ethereum wallets.",
        demoLink : "https://cryptoplexmanagement.com/"
    },
    {
        img : require('../images/projects/ION-Logo.jpg'),
        projectTitle : "ION Bahamas",
        position : "Web Developer",
        subTitle : "Non-Profit Work",
        description : "Used React and Express frameworks to build out website that automate the tracking of memberships and donations.",
        demoLink : "https://ionbahamas.org"
    }
];