import {
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
  } from 'react-share';

export default (props : any) => 
  <>
    <FacebookIcon className="socialMediaSpacing" size={40} round={true} onClick={()=>window.open("https://www.facebook.com/nett.shadi/")}/>
    <TwitterIcon className="socialMediaSpacing" size={40} round={true} onClick={()=>window.open("https://twitter.com/rashad_barnett")}/>
    <LinkedinIcon className="socialMediaSpacing" size={40} round={true} onClick={()=>window.open("https://www.linkedin.com/in/rashadbarnett/")}/>
  </>