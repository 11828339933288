import './App.css';
import CreateNavItem from './components/devLogo'
import { Button } from 'reactstrap';
import SocialMedia from './components/socialMedia'
import Nav from './components/navBar'

const list = [
  {img : require('./images/node.png'), text : "NodeJS", link : "https://nodejs.org/en/about/"},
  {img : require('./images/react.png'), text : "ReactJS", link : "https://reactjs.org/docs/getting-started.html"},
  {img : require('./images/springBoot.png'), text: "SpringBoot", link : "https://docs.spring.io/spring-boot/docs/current/reference/htmlsingle/"},
  {img : require('./images/git.png'), text: "Git", link : "https://git-scm.com/about"}
];

const App = () => { 
  return (
  <div>
  <header>
    <Nav />
  </header>
  <div className="App-header" style={{paddingTop : '60px'}}>
    <div className="avatarWrapper" >
        <div>
          <img  className="roundImg" src={require('./images/me.jfif')} alt="Avatar" width="250" height="250"/>
          <br />
          <h3 className="title" style={{ textAlign: "center" }}>
            Rashad Barnett<br />
            Software Engineer
          </h3>
        </div>
        <div className="container-flex">
          <SocialMedia />
          {/* <FacebookIcon className="socialMediaSpacing" size={40} round={true} onClick={()=>window.open("https://www.facebook.com/nett.shadi/")}/>
          <TwitterIcon className="socialMediaSpacing" size={40} round={true} onClick={()=>window.open("https://twitter.com/rashad_barnett")}/>
          <LinkedinIcon className="socialMediaSpacing" size={40} round={true} onClick={()=>window.open("https://www.linkedin.com/in/rashadbarnett/")}/>
          <EmailIcon className="socialMediaSpacing" size={40} round={true} onClick={()=>window.location="mailto:rashad@rashadbarnett.com"}/> */}
        </div>
    </div> 
    <div id="div_top_hypers">
      <div className="container-flex">
          {list.map(({ text, img , link }) => <CreateNavItem text={text} link={link} img={img}/>)}
      </div>
    </div>
  </div>
  <footer>
  </footer>
</div> 
)
}

export default App;