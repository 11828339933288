import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button,
} from 'reactstrap';
let year = (start: number, finish: number) => {
  let year = 1;
  if (!finish){
    year =  new Date().getFullYear() - start;
  }
  else{
   year = finish - start 
  }
  if (year < 1) return `<1 year`;
  return `${year} years`;
}

export default (props : any) => 
  <div className="grow" style={{minWidth : "300px", width : '25%', padding : '15px'}}>
    <Card>
      <CardBody>
      <CardTitle><b>{props.projectTitle}</b></CardTitle>
        <CardSubtitle><i>{props.position}</i></CardSubtitle>
        <CardImg top width="100%" height="250px" src={props.img} alt="Card image cap" />
      </CardBody>
      <CardBody style={{backgroundColor : '#eeeeee'}}>
      <CardSubtitle><b>{props.subTitle}</b></CardSubtitle>
      <CardText>{props.description}</CardText>
        <Button 
          outline 
          color="secondary"
          onClick = {() => window.open(props.demoLink)}
          >Demo
        </Button>
      </CardBody>
    </Card>
  </div>